<template>
    <section class="testimonials">
        <h2 class="heading text-center">BEWERTUNGEN</h2>
        <div class="testimonial_list container">
            <div v-for="testimonial, index in testimonials" :key="index" class="testimonial">
                <h3 class="">{{ testimonial.name }}</h3>
                <span class="comma">“</span>
                <p class="text-center  mb-0">{{ testimonial.description }}</p>
                <img :src="require('@/esf_kerkrade_vitanatura/assets/images/five-stars-rating-icon.png')" />
            </div>
        </div>
        <div class="testimonial_slider container">
            <VueSlickCarousel :dots="true" :arrows="true">
                <div class="testimonial" v-for="testimonial, index in testimonials" :key="index">
                    <h3 class="">{{ testimonial.name }}</h3>
                    <span class="comma">“</span>
                    <p class="text-center  mb-0">{{ testimonial.description }}</p>
                    <img :src="require('@/esf_kerkrade_vitanatura/assets/images/five-stars-rating-icon.png')" />
                </div>
            </VueSlickCarousel>
        </div>

    </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
export default {
    data() {
        return {
            testimonials: [
                { name: "Lorem Ipsum", description: "Super unkomplizierte Bestellung und schnelle Lieferung." },
                { name: "Lorem Ipsum", description: "Super unkomplizierte Bestellung und schnelle Lieferung." },
                { name: "Lorem Ipsum", description: "Super unkomplizierte Bestellung und schnelle Lieferung." }
            ]
        }
    },
    components: { VueSlickCarousel }
}
</script>
<style lang="scss">
.testimonial .comma {
    font-family: "Molengo", sans-serif;
    font-size: 86px;
    font-weight: 400;
    text-align: center;
    position: absolute;
}

.desktop {


    .testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 3px solid #408309;
        border-radius: 3px;
        padding-inline: 76px;
        padding-block: 37px 24px;
        position: relative;
        max-width: 535px !important;
        height: 248px;
    }

    .testimonial h3 {
        font-size: 30px;
        font-style: italic;
        font-weight: 700;
        line-height: 38px;
        padding-bottom: 22px;
    }

    .testimonial .comma {
        top: 15%;
        left: 15%;
    }

    .testimonial p {
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        padding-bottom: 10px;
    }
}

.mobile {

    .testimonial {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 3px solid #408309;
        border-radius: 3px;
        padding-inline: 46px;
        padding-block: 20px 10px;
        position: relative;
        max-width: 100% !important;
    }

    .testimonial h3 {
        font-size: 17px;
        font-style: italic;
        font-weight: 700;
        line-height: 21px;
        text-align: center;
        margin-bottom: 15px;
    }

    .testimonial .comma {
        top: 10%;
        left: 18%;

    }

    .testimonial p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        margin-bottom: 15px !important;

    }
}



@media (max-width: 769px) {
    .testimonial .comma {
        top: 27% !important;
        left: 18% !important;
    }
}

@media (max-width: 453px) {
    .testimonial .comma {
        top: 27% !important;
        left: 5% !important;
    }
}
</style>