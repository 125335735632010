<template>
  <div id="hero_banner">
    <div v-if="mobileCheck == 'browser'" class="slider d-lg-flex">
      <div v-for="(bSlide, index) in slider.slides" :key="index" class="col slide p-20 3"
        v-bind:style="{ 'background-image': 'url(' + bSlide.optImage + ')' }">
        <div class="d-flex border p-15">
          <div class="p-0 d-flex">
            <div class="card align-self-end w-100">
              <div class="card-body p-0">
                <h5 class="card-title" v-html="bSlide.title"></h5>
                <h6 class="card-text" v-html="bSlide.description"></h6><br>
                <button class="slider_btn btn-warning text-uppercase" v-html="bSlide.buttonText"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mobileCheck == ['mobile' || 'tablet']">
      <VueSlickCarousel :dots="true" :arrows="false">
        <div class="col slide py-15 px-0" v-for="(bSlide, index) in slider.slides" :key="index"
          v-bind:style="{ 'background-image': 'url(' + bSlide.optImage + ')' }">
          <div class="d-flex border-top border-bottom ">
            <div class="p-0 d-flex">
              <div class="card align-self-center w-100">
                <div class="card-body p-0">
                  <h5 class="card-title" v-html="bSlide.title"></h5>
                  <h6 class="card-text" v-html="bSlide.description"></h6><br>
                  <button class="slider_btn btn-warning text-uppercase" v-html="bSlide.buttonText"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>

</template>

<script>
import { isMobile, isTablet, isBrowser } from "mobile-device-detect";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "HeroBanner",
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    slider() {
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.larger;
          } else {
            element.optImage = element.media.medium;
          }
        });
      }
      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  components: { VueSlickCarousel }
};
</script>
<style lang="scss">
#hero_banner {
  .slide {
    background-repeat: no-repeat;
    background-size: cover;

    .card {
      background-color: rgba(255, 255, 255, 0.82) !important;
    }

    &>div {
      height: 100%;
    }
  }
}

.desktop #hero_banner {
  .slider {
    .slide {
      aspect-ratio: 239 / 137;
      .card {
        min-height: 210px !important;
        border-radius: 10px;
        padding: 25px 40px 15px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
      }

      .card-body {
        h5.card-title {
          font-weight: 700;
          font-style: italic;
          font-size: 28px;
          line-height: 35px;
        }

        h6.card-text {
          font-size: 22px;
          font-weight: 400;
          line-height: 27px;

          p {
            margin: 0;
          }
        }

        .slider_btn {
          font-size: 23px;
          font-style: italic;
          font-weight: 700;
          line-height: 28px;
          padding-inline: 25px;
          padding-block: 8px;
          border-radius: 5px;
          border: none;
        }
      }

      &:nth-child(even) {
        &>div {
          justify-content: end;

          .card-body {
            text-align: end;
          }
        }
      }
    }

    div.slide>div>div {
      width: calc(100% - 45%) !important;
    }
  }
}

@media (max-width: 768px) {
  #hero_banner {
    .slide {
      height: 440px;

      &>div {
        height: 100%;
      }

      div.slide>div>div {
        width: 55%;
      }
    }

    .card-body {
      text-align: end;

      h5.card-title {
        font-size: 13px;
        font-style: italic;
        font-weight: 700;
        line-height: 16.35px;
      }

      h6.card-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;

        p {
          margin: 0;
        }
      }

      .slider_btn {
        font-size: 10px;
        line-height: 13px;
        font-style: italic;
        font-weight: 700;
        padding-inline: 25px;
        padding-block: 8px;
        border-radius: 2px;
        border: none;
      }
    }

    .slick-slide:not(.slick-cloned) {
      &:nth-child(odd) {
        .slide>div {
          justify-content: end;

          .card {
            border-radius: 10px 0 0 10px;
            padding: 11px 15px 8px;

            .card-body {
              text-align: end;
            }
          }
        }
      }

      &:nth-child(even) {
        .slide>div {
          .card {
            border-radius: 0 10px 10px 0;
            padding: 11px 15px 8px;

            .card-body {
              text-align: start;
            }
          }
        }
      }
    }

    div.slide>div>div {
      width: calc(100% - 45%) !important;
    }

  }
}

@media (max-width: 453px) {
  #hero_banner {
    .slide {
      height: 245px;
    }
  }
}
</style>
