<template>
    <div
      class="product-card--holder w-100 d-flex flex-column"
      v-match-heights="{ el: ['.available-sizes-list'] }"
    >
      <b-link
        :to="`/${product.url_key}`"
        class="product-card"
      >
        <div class="text-center">
          <img
            class="product-card--img-top"
            :src="product.image.medium"
            width="240"
            height="220"
            v-if="product.image && product.image.medium"
            @error="setDefaultPlaceholder"
          />
          <img
            class="product-card--img-top"
            width="240"
            height="220"
            v-else
            src="@/base/assets/default-placeholder-image.jpg"
          />
          <div class="actionbuttons">
            <span class="sale-txt" v-if="onSale == true">Sale</span>
            <span class="new-txt" v-if="onNew == true">Nieuw</span>
            <span
              class="empty-txt"
              v-if="onNew == false && onSale == false"
            ></span>
          </div>
        </div>
  
        <div class="product-card--product-name">{{ product.name }}</div>
        <ul
          v-for="attr of product.configurable_options"
          :key="attr.id"
          class="available-sizes-list d-none d-md-flex flex-wrap justify-content-center list-unstyled p-0"
          ref="productOptions"
        >
          <li v-for="opt of attr.values" :key="opt.value_index">
            {{ opt.label }}
          </li>
        </ul>
    </b-link>
    <div class="product-details-wrapper px-20  mt-auto">
      <p class="m-0 text-info">PRODUKTNUMMER : {{ product.sku }}</p>
      <p v-if="product.inhalt" class="m-0 text-info">INHALT : {{ product.inhalt }}</p>
      <Rating
        :stars="product.rating_summary"
        v-if="product.review_count > 0"
      />
    </div>
    <div class="bottombox d-flex justify-content-between align-items-center px-20 pt-20">
      <h3 class="d-flex font-weight-bold mb-0">
        <span
          v-html="getProductPriceRange.text"
          class="d-block product-card--price"
        >
      </span>
      <span>*</span>
      </h3>
      <ProductAddtoCart :product="product" />

      <span
        class="d-block product-card--sale-comment"
        v-if="product.price_range.minimum_price.discount.percent_off > 0"
        >{{ $t("discount") }}
        {{
          product.price_range.minimum_price.discount.percent_off.toFixed(0)
        }}%</span
      >
      <span
        class=" product-card--sale-comment"
        style="display: none"
        v-if="product.price_range.minimum_price.discount.percent_off == 0"
        >{{ $t("discount") }}
        {{
          product.price_range.minimum_price.discount.percent_off.toFixed(0)
        }}%</span
      >
    </div>
    <span class="pb-20 px-20 bottom_text">Preise exkl. MwSt. zzgl. Versandkosten</span>

    </div>
  </template>
  
  <script>
  import Rating from "@/esf_kerkrade_vitanatura/core/components/Rating";
  import ProductAddtoCart from "@/esf_kerkrade_vitanatura/core/components/product/ProductAddtoCart";

  
  export default {
    name: "ProductCard",
    props: {
      product: {
        type: Object,
        require: true,
      },
      optionHeight: {
        type: Number,
        require: true,
      },
    },
    data() {
      return {};
    },
    components: {
      Rating,
      ProductAddtoCart,
    },
    computed: {
      onSale() {
        return this.product.sale;
      },
      onNew() {
        return this.product.new;
      },
      isLoggedIn() {
        return this.$store.getters["user/getIsLoggedIn"];
      },
      getBottomMargin() {
        if (
          (this.product.sale == 1 || this.product.new == 1) &&
          (this.product.price_range.minimum_price.discount.percent_off > 0 ||
            this.product.price_range.maximum_price.discount.percent_off > 0)
        ) {
          return "m20";
        } else {
          return "";
        }
      },
      getProductPriceRange() {
        if (this.product.__typename == "SimpleProduct") {
          return {
            text:
              '<span class="currentPrice">' +
              this.formatCurrency(
                this.product.price_range.maximum_price.final_price.value
              ) +
              "</span>",
            discountType: 1,
          };
        } else if (this.product.__typename == "BundleProduct") {
          let lowestPrice =
            this.product.price_range.minimum_price.final_price.value;
          let highestPrice =
            this.product.price_range.maximum_price.final_price.value;
          if (lowestPrice == highestPrice) {
            return {
              text:
                '<span class="strike">' +
                this.formatCurrency(
                  this.product.price_range.maximum_price.regular_price.value
                ) +
                '</span> <span class="currentPrice">' +
                this.formatCurrency(lowestPrice) +
                "</span>",
              discountType: 1,
            };
          } else {
            return {
              text:
                '<span class="currentPrice">' +
                this.formatCurrency(lowestPrice) +
                " - " +
                this.formatCurrency(highestPrice) +
                "</span>",
              discountType: 2,
            };
          }
        } else {
          if (this.productConfig) {
            let lowestPrice =
              this.product.price_range.maximum_price.regular_price.value;
            let highestPrice =
              this.product.price_range.minimum_price.final_price.value;
            if (this.productConfig.variants) {
              this.productConfig.variants.forEach((v) => {
                if (
                  v.product.price_range.minimum_price.final_price.value <
                  lowestPrice
                ) {
                  lowestPrice =
                    v.product.price_range.minimum_price.final_price.value;
                }
                if (
                  v.product.price_range.maximum_price.final_price.value >
                  highestPrice
                ) {
                  highestPrice =
                    v.product.price_range.maximum_price.final_price.value;
                }
              });
            }
            if (
              lowestPrice == highestPrice &&
              lowestPrice !==
                this.product.price_range.maximum_price.regular_price.value
            ) {
              return {
                text:
                  '<span class="strike">' +
                  this.formatCurrency(
                    this.product.price_range.maximum_price.regular_price.value
                  ) +
                  '</span> <span class="currentPrice">' +
                  this.formatCurrency(lowestPrice) +
                  "</span>",
                discountType: 1,
              };
            } else if (lowestPrice !== highestPrice) {
              return {
                text:
                  '<span class="currentPrice">' +
                  this.formatCurrency(lowestPrice) +
                  " / " +
                  this.formatCurrency(highestPrice) +
                  "</span>",
                discountType: 2,
              };
            } else {
              return {
                text:
                  '<span class="currentPrice">' +
                  this.formatCurrency(lowestPrice) +
                  "</span>",
                discountType: 1,
              };
            }
          } else {
            return 1;
          }
        }
      },
      productConfig() {
        return { variants: this.product.variants };
      },
    },
    methods: {
      toggleWishItem() {
        if (this.$store.getters["user/getIsLoggedIn"] == true) {
          if (
            this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
            true
          ) {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
          } else {
            if (this.product.__typename == "SimpleProduct") {
              this.$store.dispatch("user/setProductWishlistStatus", {
                sku: this.product.sku,
                parentSku: null,
              });
            } else {
              const msg = {
                type: "danger",
                title: this.$t("wishlist error"),
                text: this.$t("wishlist_select_options"),
              };
              this.$store.dispatch("messages/sendMessage", { message: msg });
              this.$router.push("/" + this.product.url_key);
            }
          }
        } else {
          const msg = {
            type: "danger",
            title: this.$t("wishlist error"),
            text: this.$t("wishlist_logged_in"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      },
      formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
      },
      setDefaultPlaceholder() {
        this.product.image.medium = null;
      },
    },
  };
  </script>
  